<template>
  <div class=" inner footer"></div>
</template>

<style></style>

<script>
export default {
  name: "footersite",
};
</script>
